
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    title: String,
  },
  setup(props) {
    //#region Data
    
    //#endregion
    
    //#region Methods
    
    //#endregion
    
    return {
      props,

      //#region Data
      
      //#endregion
      
      //#region Methods
      
      //#endregion
    }
  },
})
